exports.components = {
  "component---src-components-pages-about-tsx": () => import("./../../../src/components/pages/about.tsx" /* webpackChunkName: "component---src-components-pages-about-tsx" */),
  "component---src-components-pages-contact-tsx": () => import("./../../../src/components/pages/contact.tsx" /* webpackChunkName: "component---src-components-pages-contact-tsx" */),
  "component---src-components-pages-faq-tsx": () => import("./../../../src/components/pages/faq.tsx" /* webpackChunkName: "component---src-components-pages-faq-tsx" */),
  "component---src-components-pages-home-index-tsx": () => import("./../../../src/components/pages/home/index.tsx" /* webpackChunkName: "component---src-components-pages-home-index-tsx" */),
  "component---src-components-pages-how-it-works-tsx": () => import("./../../../src/components/pages/how-it-works.tsx" /* webpackChunkName: "component---src-components-pages-how-it-works-tsx" */),
  "component---src-components-pages-pricing-index-tsx": () => import("./../../../src/components/pages/pricing/index.tsx" /* webpackChunkName: "component---src-components-pages-pricing-index-tsx" */),
  "component---src-components-pages-privacy-tsx": () => import("./../../../src/components/pages/privacy.tsx" /* webpackChunkName: "component---src-components-pages-privacy-tsx" */),
  "component---src-components-pages-terms-tsx": () => import("./../../../src/components/pages/terms.tsx" /* webpackChunkName: "component---src-components-pages-terms-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

